import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

	public menu;

  constructor(
		private menuService: MenuService
	) {
	}

  ngOnInit() {
  	this.getMenu();
  }


  public getMenu() {
		this.menuService.getMenu().subscribe(
			(data) => {
				this.menu = data;
				console.log(this.menu);
			}
		)
	}

}
