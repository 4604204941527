import { Routes, RouterModule } from '@angular/router';
import { AizoComponent } from './aizo/aizo.component';

export let ROUTES: Routes = [
	{
		path: '',
		component: AizoComponent
	}
];

export const routing = RouterModule.forChild(ROUTES);
