import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
  	private http: HttpClient
	) { }

	public getMenu() {
  	return this.http.get(environment.apiUri + '/' + 'menu');
	}

}
